@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

code {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
