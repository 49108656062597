.image{

    &__gallery{
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 2rem;
    }
}


@media screen and (max-width: 768px){

    .image{
        &__gallery{
            margin-top: 1rem;
            margin-left: 1.75rem;
            margin-right: 1.75rem;
        }
    }
    
}