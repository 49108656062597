@keyframes movement_1 {
	0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes movement_2 {
	0% {
    opacity: 0.33;
  }
  100% {
    opacity: 1;
  }
}

@keyframes movement_3 {
	0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes movement_4 {
	0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sky {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  	z-index: -4;
	overflow: hidden;
}

.sky__phase {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: opacity 0.2s;
	z-index: -4;
	overflow: hidden;
}

.sky__0__4 {
	background: linear-gradient(
		0deg,
		rgba(10, 1, 33, 1) 0%,
		rgba(4, 0, 14, 1) 100%
	);
	animation: linear movement_3 1 5s forwards;
}

.sky__4__8__m {
	background: linear-gradient(
		0deg,
		rgba(225, 210, 196, 1) 0%,
		rgba(195, 225, 243, 1) 80%
	);
	animation: linear movement_4 1 5s forwards;
}

.sky__4__8__s {
	background: linear-gradient(
		0deg,
		rgba(225, 210, 196, 1) 0%,
		rgba(195, 225, 243, 1) 80%
	);
	animation: linear movement_1 1 5s forwards;
}

.sky__8__12 {
	background: linear-gradient(
		0deg,
		rgba(255, 244, 214, 1) -20%,
		rgba(140, 184, 228, 1) 100%
	);
	animation: linear movement_2 1 5s forwards;
}

.sky__12__16 {
	background: linear-gradient(
		0deg,
		rgba(205, 237, 246, 1) 0%,
		rgba(111, 172, 225, 1) 90%
	);
	animation: linear movement_3 1 5s forwards;
}

.sky__16__20__m {
	background: linear-gradient(
		0deg,
		rgba(206, 172, 144, 1) -10%,
		rgba(101, 118, 176, 1) 100%
	);
	animation: linear movement_1 1 5s forwards;
}

.sky__16__20__s {
	background: linear-gradient(
		0deg,
		rgba(206, 172, 144, 1) -10%,
		rgba(101, 118, 176, 1) 100%
	);
	animation: linear movement_4 1 5s forwards;
}

.sky__20__24 {
	background: linear-gradient(
		0deg,
		rgba(100, 125, 176, 0.6) -40%,
		rgba(10, 0, 94, 1) 100%
	);
	animation: linear movement_2 1 5s forwards;
}

.sun {
	position: absolute;
	bottom: 0;
	margin: 20px;
	width: 80px;
	height: 80px;
	background-color: rgb(254, 215, 102);
	border-radius: 50%;
	box-shadow: 0 0 14px 14px rgba(254, 215, 102, 0.2);
	z-index: -4;
}

.moon {
	position: absolute;
	bottom: 0;
	margin: 20px;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 0 7px 7px rgba(255, 255, 255, 0.2);
	z-index: -4;
	filter: brightness(0.75);

}

/* #leaves {
	position:absolute;
	top:-50px;
	width:100%;
	text-align: right;
	z-index: -4;
}

#leaves i {
    display: inline-block;
    width: 200px;
    height: 150px;
    background: linear-gradient(to bottom right, #309900, #005600);
    transform: skew(20deg);
    border-radius: 5% 40% 70%;
    box-shadow: inset 0px 0px 1px #222;
    border: 1px solid #333;
    animation: falling 5s 0s infinite;
}

#leaves i:nth-of-type(2n) { animation: falling2 5s 0s infinite; }
#leaves i:nth-of-type(3n) { animation: falling3 5s 0s infinite; }

#leaves i:before {
  position: absolute;
  content: '';
  top: 117px;
  right: 9px;
  height: 27px;
  width: 32px;
  transform: rotate(49deg);
  border-radius: 0% 15% 15% 0%;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 0px solid #222;
  border-right: 1px solid #222;
  background: linear-gradient(to right, rgba(0,100,0,1), #005600);
}

#leaves i:after {
  content: '';
  height: 125px;
  width: 10px;
  background: linear-gradient(to right, rgba(0,0,0,.15), rgba(0,0,0,0));
  display: block;
  transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius:50%;
}


#leaves i:nth-of-type(n)    { height:23px; width:30px; }
#leaves i:nth-of-type(n):before { width:7px; height:5px; top:17px; right:1px; }
#leaves i:nth-of-type(n):after { width:2px; height:17px; left: 12px; top:0px; }

#leaves i:nth-of-type(2n+1)    { height:11px; width:16px; }
#leaves i:nth-of-type(2n+1):before { width:4px; height:3px; top:7px; right:0px; }
#leaves i:nth-of-type(2n+1):after { width:2px; height:6px; left: 5px; top:1px; }

#leaves i:nth-of-type(3n+2)  { height:17px; width:23px; }
#leaves i:nth-of-type(3n+2):before  { height:4px; width:4px; top:12px; right:1px; }
#leaves i:nth-of-type(3n+2):after  { height:10px; width:2px; top:1px; left:8px; }

#leaves i:nth-of-type(n)   { animation-delay: 1.9s;}
#leaves i:nth-of-type(2n)  { animation-delay: 3.9s;}
#leaves i:nth-of-type(3n)  { animation-delay: 2.3s;}
#leaves i:nth-of-type(4n)  { animation-delay: 4.4s;}
#leaves i:nth-of-type(5n)  { animation-delay: 5s;  }
#leaves i:nth-of-type(6n)  { animation-delay: 3.5s;}
#leaves i:nth-of-type(7n)  { animation-delay: 2.8s;}
#leaves i:nth-of-type(8n)  { animation-delay: 1.5s;}
#leaves i:nth-of-type(9n)  { animation-delay: 3.3s;}
#leaves i:nth-of-type(10n) { animation-delay: 2.5s;}
#leaves i:nth-of-type(11n) { animation-delay: 1.2s;}
#leaves i:nth-of-type(12n) { animation-delay: 4.1s;}
#leaves i:nth-of-type(13n) { animation-delay: 1s;  }
#leaves i:nth-of-type(14n) { animation-delay: 4.7s;}
#leaves i:nth-of-type(15n) { animation-delay: 3s;  }

#leaves i:nth-of-type(n)    { background: linear-gradient(to bottom right, #309900, #005600); }
#leaves i:nth-of-type(2n+2)  { background: linear-gradient(to bottom right, #5e9900, #2b5600); }
#leaves i:nth-of-type(4n+1)  { background: linear-gradient(to bottom right, #990, #564500); }

#leaves i:nth-of-type(n)    { opacity: .7;}
#leaves i:nth-of-type(3n+1)  { opacity: .5;}
#leaves i:nth-of-type(3n+2)  { opacity: .3;}

#leaves i:nth-of-type(n)    {transform: rotate(180deg);}


#leaves i:nth-of-type(n) { animation-timing-function:ease-in-out;}

@keyframes falling {
    
    0% {
        transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        transform:
            translate3d(-1000px,700px,0)
            rotate(90deg);
        opacity: 0;
    }
}

@keyframes falling3 {
     0% {
        transform:
            translate3d(0,0,0)
            rotate(-20deg);
    }
    
    100% {
        transform:
            translate3d(-1000px,1000px,0)
            rotate(-70deg);
        opacity: 0;
    }
}

@keyframes falling2 {
     0% {
        transform:
            translate3d(0,0,0)
            rotate(90deg);
    }
    
    100% {
        transform:
            translate3d(-1000px,1000px,0)
            rotate(0deg);
        opacity: 0;
    }
} */

@media screen and (max-width: 768px){
    .sun, .moon{
        display: none;
    }
    
}

.stars {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.star {
  width: 3px;
  height: 3px;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  z-index: -1;
  filter: brightness(0.75);
}