.about {
  
    &__container {
      max-width: 1100px;
      margin: 0 auto;
    }
  
    &__content {
      display: grid;
      gap: 3rem;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1.5fr));
      align-items: center;
      justify-content: center;
    }
  
    &__data {
      padding: 2rem;

      &__card {

        grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));
        background: rgba( 255, 255, 255, 0 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 1px );
        -webkit-backdrop-filter: blur( 1px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        margin-bottom: 3rem;
      }
    }
  
    &__title {
      @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');      font-family: 'Dancing Script', cursive;
      font-family: 'Caveat', cursive;
      // @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
      // font-family: 'Indie Flower', cursive;
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
      text-align: center;
    }
  
    &__img {
      display: block;
      width: 90%;
      margin: 1rem auto;
      height: auto;
      border-radius: 5%;
    }
  
    &__paragraph {
      
      display: grid;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      line-height: 1.6;
      margin-bottom: 1.5rem;
      text-align: left;
      margin-left: 3rem;
      margin-right: 3rem;
      margin-top: 1rem;

      ul{
        list-style-type: circle;
        font-size: 1.05rem;
        margin: auto;
        margin-left: 0.5rem;
      }

      li{
        margin: 0.5rem;
      }

      p{
        margin: 0.5rem;
        text-align: center;
      }
      

    }
  }

  #intro{

    p{
      text-align: left;
    }
  }

  @media screen and (max-width: 992px) {
    .about__content {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  
    .about__paragraph {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  
    .about__data__card {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
  
  @media screen and (max-width: 768px){
    .about__title {
      font-size: 2rem;
    }
  
    .about__img {
      width: 80%;
    }
  
    .about__paragraph {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 300px) {
    .about__title {
      font-size: 1.5rem;
    }
  
    .about__img {
      width: 70%;
    }
  
    .about__paragraph {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: 1rem;

      ul{
        margin-left: 2rem;
      }
    }
  
    .about__data__card {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  
  