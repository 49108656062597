@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

:root{
    --header-height: 3rem;

    /* Colors */
    --hue: 0;
    --sat: 0%;
    --title-color: hsl(var(--hue), var(--sat), 10%);
    --title-color-dark: hsl(var(--hue), var(--sat), 0%);
    --text-color: hsl(var(--hue), var(--sat), 30%);
    --body-color: hsl(var(--hue), var(--sat), 98%);
    --container-color: hsl(var(--hue), var(--sat), 100%);

    --body-font: 'Poppins', sans-serif;

    /* Font Sizes */
    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.5rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.75rem;
    --tiny-font-size: 0.625rem;

    /* Font Weights */
    --font-normal: 400;
    --font-medium: 500;
    --font-semibold: 600;

    /* Spacing */
    --mb-0-25: 0.25rem;
    --mb-0-5: 0.5rem;
    --mb-0-75: 0.75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /* Z-index */
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    height: fit-content;
}

body, button, input, textarea {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    font-weight: var(--font-normal);
}

body {
    background-color: var(--body-color);
    height: fit-content;
    // background-color: black;
    color: var(--text-color);
    min-height: 100vh;
}

h1, h2, h3 {
    font-weight: var(--font-semibold);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
    border: none;
    outline: none;
}

img {
    max-width: 100%;
    height: auto;
}

.section{
    padding: 6rem 0 2rem;
}

.section__title{
    font-size: var(--h1-font-size);
    color: var(--title-color);
}

.section__subtitle{
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 4rem;
}

.section__title, .section__subtitle{
    text-align: center;
}

.container {
    max-width: 968px;
    margin-left: auto;
    margin-right: auto;
}

.grid {
    display: grid;
    gap: 1.5rem;
}

.button {
    display: inline-block;
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    background-color: var(--title-color);
    color: var(--container-color);
    font-weight: var(--font-medium);
    transition: background-color 0.3s ease;

    &:hover {
        background-color: hsl(var(--hue), var(--sat), 20%);
    }
}

.button__icon {
    margin-right: var(--mb-0-5);
}

.button__flex {
    display: inline-flex;
    align-items: center;
}

@media screen and (max-width: 992px) {
    :root {
        --big-font-size: 3rem;
        --h1-font-size: 2rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1.125rem;
        --normal-font-size: 0.875rem;
        --small-font-size: 0.75rem;
        --smaller-font-size: 0.625rem;
    }

    .container {
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
}

@media screen and (max-width: 768px) {
    :root {
        --big-font-size: 2.5rem;
        --h1-font-size: 2rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: 0.75rem;
        --smaller-font-size: 0.625rem;
    }

    .section {
        padding: 2rem 0 4rem;
    }
}

@media screen and (max-width: 576px) {
    :root {
        --big-font-size: 2rem;
        --h1-font-size: 1.25rem;
        --h2-font-size: 1.125rem;
        --h3-font-size: 1rem;
        --normal-font-size: 0.875rem;
        --small-font-size: 0.75rem;
        --smaller-font-size: 0.625rem;
    }
}

@media screen and (max-width: 350px){
    :root{
        --big-font-size: 1.75rem;
        --h1-font-size: 1.125rem;
        --h2-font-size: 1rem;
        --h3-font-size: 0.875rem;
        --normal-font-size: 0.75rem;
        --small-font-size: 0.625rem;
        --smaller-font-size: 0.5rem;
    }

    .container {
        margin-left: var(--mb-1-0);
        margin-right: var(--mb-1-0);
    }
}
