.project-details {
    &__container {
      display: grid;
      grid-template-columns: 100px repeat(3, 1fr);
      grid-gap: 20px;
      position: relative;
      margin-left: auto;
      margin-right: auto; 
    }

    &__back-button{
      grid-column: 1;
      grid-row: 2;
      font-size: 2rem;
      display: flex;
      justify-content: right;
      background: transparent;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  
    &__content {
      grid-column: 1 / -1;
    }
  
    &__data {
      display: grid;
      grid-template-columns: 100px repeat(3, 1fr);
    }
  
    &__title {
      grid-column: 2 / -1;
      margin: 0;
      font-size: 2rem;
    }
  
    &__subtitle {
      grid-column: 2 / span 2;
      margin: 0;
      font-size: 1.25rem;
      margin-bottom: 1rem;
      font-style: italic;
    }
  
    &__date {
      grid-column: 4;
      margin: 0;
      font-size: 1rem;
      text-align: end;
      font-style: italic;
      margin-right: 1rem;
    }
  
    &__img {
      grid-row: 3;
      grid-column: 1 / -1;
      max-width: 40%;
      border-radius: 3%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  
    &__description {
      grid-column: 1 / span 3;
      grid-row: 4 / 6;
      margin: 0;
      font-weight: 400;
      margin-left: 3rem;
      margin-top: 1rem;

      h1, h2, h3, p{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      h1{
        font-size: 2rem;
      }

      h2{
        font-size: 1.5rem;
      }

      h3{
        font-size: 1.2rem;
      }

      p{
        font-size: 1rem;
      }
      ul{
        margin-left: 1rem;
        list-style-type: disc;
      }
  
      &__paragraphs {
        margin-bottom: 1rem;
        font-size: 1.2rem;
      }
    }

    &__others{
      grid-column: 4;
      grid-row: 4;
      margin: 0;
      font-size: 1.2rem;
      text-align: end;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  
    &__skills {
      margin-right: 1rem;
      &__title {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }
  
      &__values {
        margin: 0;
        font-size: 1rem;
        display: block;
      }
    }

    &__topics{
      margin-right: 1rem;
      &__title {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }
  
      &__values {
        margin: 0;
        font-size: 1rem;
        display: block;

      }
    }

    &__references {
      margin-right: 1rem;
      &__title {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }
  
      &__links {
        margin: 0;
        font-size: 1rem;
        color: inherit;
        text-decoration: none;
        display: block;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  
  p{
    margin-bottom: 1rem;
  }

@media screen and (max-width: 768px) {
  .project-details {
    &__container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      margin: auto;
    }

    &__back-button{
      display: none;
    }
  
    &__content {
      grid-column: 1 / -1;
    }
  
    &__data {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 1rem;
      margin-top: 0rem;
    }
  
    &__title {
      grid-column: 1 / span 3;
      margin: 0;
      font-size: 2rem;
      text-align: center;
    }
  
    &__subtitle {
      grid-column: 1 / span 2;
      margin: 0;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      font-style: italic;
    }
  
    &__date {
      grid-column: 3;
      margin: 0;
      font-size: 1rem;
      text-align: end;
    }
  
    &__img {
      grid-row: 3;
      grid-column: 1 / span 3;
      max-width: 70%;
      border-radius: 1%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
    }
  
    &__description {
      grid-column: 1 / span 3;
      grid-row: 4 / 6;
      margin: 0;
      margin-top: 1rem;
      font-weight: 400;
    }

    &__others{
      display: grid;
      grid-column: 1 / span 3;
      grid-row: 6;
      margin: 0;
      margin-top: 1rem;
      font-size: 1.2rem;
      text-align: start;
    }
  
    &__skills {
      grid-column: 1;
      &__title {
        margin: 0;
        font-size: 1.2rem;
      }
  
      &__values {
        margin: 0;
        margin-top: 0.75rem;
        font-size: 1rem;
        display: block;

      }
    }

    &__topics{
      grid-column: 2;
      &__title {
        margin: 0;
        font-size: 1.2rem;
      }
  
      &__values {
        margin: 0;
        margin-top: 0.75rem;
        font-size: 1rem;
        display: block;

      }
    }
  
    &__references {
      grid-column: 3;
      &__title {
        margin: 0;
        font-size: 1.2rem;
      }
  
      &__links {
        margin: 0;
        margin-top: 0.75rem;
        font-size: 1rem;
        color: inherit;
        text-decoration: none;
        display: block;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}