.sky {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  	z-index: -1;
	overflow: hidden;
}

.sky__phase__d {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: opacity 0.2s;
	z-index: -1;
	overflow: hidden;
}

.sky__0__4__2 {
	background: linear-gradient(
		0deg,
		rgba(10, 1, 33, 1) 0%,
		rgba(4, 0, 14, 1) 100%
	);
}

.sky__4__8__2 {
	background: linear-gradient(
		0deg,
		rgba(225, 210, 196, 1) 0%,
		rgba(195, 225, 243, 1) 80%
	);
}

.sky__8__12__2 {
	background: linear-gradient(
		0deg,
		rgba(255, 244, 214, 1) -20%,
		rgba(140, 184, 228, 1) 100%
	);
}

.sky__12__16__2 {
	background: linear-gradient(
		0deg,
		rgba(205, 237, 246, 1) 0%,
		rgba(111, 172, 225, 1) 90%
	);
}

.sky__16__20__2 {
	background: linear-gradient(
		0deg,
		rgba(206, 172, 144, 1) -10%,
		rgba(101, 118, 176, 1) 100%
	);
}

.sky__20__24__2 {
	background: linear-gradient(
		0deg,
		rgba(100, 125, 176, 0.6) -40%,
		rgba(10, 0, 94, 1) 100%
	);
}

.stars {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.star {
  width: 3px;
  height: 3px;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  z-index: -1;
  filter: brightness(0.75);
}