.projects__title {
   font-size: 3rem;
   font-weight: 600;
   margin-bottom: 1rem;
   text-align: center;
}

.projects__list {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
   grid-auto-rows: auto;
   gap: 3rem;
 }

.project__infomation {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   margin-bottom: 2rem;
}

.project__title{
   font-size: 2rem;
   font-weight: 600;
   margin-top: 0.5rem;
   margin-bottom: 1rem;
   text-align: center;
   margin-left: 0.5rem;
   margin-right: 0.5rem;
}

.project__description{
   max-width: 400px;
   text-align: center;
   align-items: center;
   margin: 0.5rem 0rem 0.5rem 2rem;
  font-weight: 300;
}

.project__img {
   width: 400px;
   height: 400px;
   object-fit: cover;
   border-radius: 10px;
   border: 1rem;
   transition: 0.4s;
   margin: 0.5rem 0rem 0.5rem 2rem;
}

 .project__card {
  background: rgba( 255, 255, 255, 0.05 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 1px );
  -webkit-backdrop-filter: blur( 1px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
   transition: transform 0.3s ease-in-out;
 }

 .project__card:hover {
   background: rgba( 0, 0, 0, 0.15 );
   box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
   backdrop-filter: blur( 1px );
   -webkit-backdrop-filter: blur( 1px );
   border-radius: 10px;
   border: 1px solid rgba( 255, 255, 255, 0.18 );
   transform: scale(1.05);
 }

 @media screen and (max-width: 993px) {
   .projects__list {
     grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
     gap: 1rem;
   }

   .project__card{
      margin: 0.5rem 0rem 0.5rem 2rem;
      width: 350px;
   }

   .project__description{
      max-width: 300px;
      margin-left: 1.55rem;
   }

   .project__title {
     font-size: 2rem;
   }

   .project__img {
     width: 300px;
     height: 300px;
     margin-left: 1.55rem;
   }
 }

 @media screen and (max-width: 768px) {
   .projects__list {
     grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
     gap: 0.5rem;
   }

   .project__card{
      margin: 0.5rem 0rem 0.5rem 2rem;
      width: 300px;
   }

   .project__title {
     font-size: 1.5rem;
   }

   .project__description{
      max-width: 250px;
   }

    .project__img {
      width: 250px;
    }
 }

@media screen and (max-width: 655px){ 
  .projects__title {
    text-align: center;
  }
  .projects__list {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    justify-items: center;
  }

  .project__card{
    align-items: center;
    margin: 0rem;
    margin-bottom: 1rem;
  }

   .project__img {
     width: 200px;
     margin-left: 3rem;
   }
}